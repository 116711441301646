<template>  
    <v-card tile class="mb-3" elevation="5">
        <div class="text-subtitle-1 white--text primary font-weight-bold pa-1 pl-2 pr-2">
            Current Target Progress
        </div>
        <div class="pa-2 producer-content-container" style="height: 300px">
            <v-skeleton-loader v-if="targetGraphLoading"
                height="100%"
                type="image"
            ></v-skeleton-loader>
            <apexchart v-else
                width="100%"
                height="100%"
                type="line"
                :options="targetChartOptions"
                :series="targetChartSeries"
            />
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { STRAIGHT_LINE_OPTIONS } from '@/constants/chart-options';

export default {
    data: (vm) => ({
        targetChartSeries: [],
        targetChartOptions: STRAIGHT_LINE_OPTIONS,
        defaultDuration: 3600000,
        everyDonateDuration: 120000,
        graphDuration: 3600000,
        timerInvervalId: null,
        NxtTriggerTime: vm.$helpers.goDateGvnType(1, 'h:mm a', 'hours'),
        initialInit: false
    }),
    computed: {
        ...mapGetters(["targetGraphData", "targetGraphLoading", "currentTargetModel"]),
    },
    watch: {
        currentTargetModel(newVal) {
            if (newVal) {
                if (!this.initialInit) this.apiCurrGraph();
                else if (this.graphDuration === this.defaultDuration || !this.$helpers.compareTwoDates(this.NxtTriggerTime)) {
                    if (this.timerInvervalId) clearInterval(this.timerInvervalId);
                    this.timerInvervalId = null;
                    this.graphDuration = this.everyDonateDuration;
                    this.NxtTriggerTime = this.$helpers.goDateGvnType(2, 'h:mm a', 'minutes');
                    this.getGrapghData();
                }
            }
        },
        targetGraphData(newVal) {             
            if (newVal) {
                setTimeout(() => {
                    this.targetChartSeries = newVal.series;
                    this.targetChartOptions.xaxis = {...this.targetChartOptions.xaxis, ...newVal.xaxis}
                    this.targetChartOptions.yaxis = {...this.targetChartOptions.yaxis, ...newVal.yaxis}
                }, 200);
            };
        }
    },
    created() {
        if (this.currentTargetModel) {
            this.initialInit = true;
            this.apiCurrGraph();
        }
    },
    methods: {
        ...mapActions(["getCurrTargetGraph"]),
        
        getGrapghData() {
            this.timerInvervalId = setInterval(() => {
                this.apiCurrGraph();
            }, this.graphDuration);
        },
        apiCurrGraph() {
            this.getCurrTargetGraph(this.currentTargetModel.id).then(response => {
                this.graphDuration = this.defaultDuration;
                this.NxtTriggerTime = this.$helpers.goDateGvnType(1, 'h:mm a', 'hours');
                clearInterval(this.timerInvervalId);
                this.timerInvervalId = null;
                this.getGrapghData();
            });
        },
    },
    beforeDestroy() {
        if (this.timerInvervalId) {
            clearInterval(this.timerInvervalId);
            this.timerInvervalId = null;
        }
    }
}
</script>
